import React, {useCallback, useEffect, useRef, useState} from "react";
import {Box, Button, IconButton, styled, Typography} from "@mui/material";
import {Add, Close, Refresh, Sync, SyncProblem} from '@mui/icons-material';
import {
    getS3urlsThunk,
    MediaUploadResponse,
    MediaUploadStatus,
    resetVideo,
    updateFilename,
    updateUploadStatus,
    UploadFilename,
} from "./mediaSlice";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {RootState} from "../../redux/store";
import {S3urlsRequest} from "../../services/api";
import {AddVideoBox} from "./AddVideoBox";
import {bytesIn1GB, formatBytes} from "../../util/file";
import {SubtitleErrorTypography} from "../../shared/styles";
import {ICommonVideoStepProps} from "../../types/stepProps";
import {Analytics} from "../../lib/analytics";
import {getFileType} from "../../constants/file";
import Logger from "../../util/logger";
import {getCFMaxFileSize, getCFMaxLength} from "../../util/storage";

export interface FeedbackUploadZoneProps extends ICommonVideoStepProps {
    showControls?: boolean;
    showReplaceVideo?: boolean;
    videoIssueReason?: string;
    uploadVideoInBackgroundCB: (droppedFile: File) => void;
    abortBackgroundUploadingCB?: () => void;
    previewUrl: string;
    updatePreviewUrl: (previewUrl: string) => void;
    selectAnotherVideoProp?: () => void;
    isMonthlyDrawSelected: boolean;
}

const VideoUploading = styled(Box)(() => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    border: "3px solid #414141",
    borderRadius: "8px",
    padding: "10px",
}));
const AbortConfirmation = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    width: "100%",
    minHeight: "200px",
    borderRadius: "8px",
}));
const AbortConfirmationTypography = styled(Typography)(() => ({
    fontSize: "18px",
    fontWeight: "600"
}));
const StyledCloseIcon = styled(Close)(({theme}) => ({
    color: theme.palette.text.primary,
    fontSize: "16px"
}));
const FailedBox = styled(Box)(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    width: "100%",
    height: "200px",
    borderRadius: "8px",
    border: `1px solid ${theme.palette.warning.main}`,
}));
const StyledRetryButton = styled(Button)(() => ({
    textTransform: "none",
    borderRadius: "20px",
}));
const ErrorButton = styled(Button)(({theme}) => ({
    textTransform: "none",
    fontSize: "14px",
    fontWeight: "500",
    color: theme.palette.warning.main
}));
const PreviewBox = styled(Box)(() => ({
    position: "relative",
}));
const ReplaceButton = styled(Button)(() => ({
    position: "absolute",
    right: "15px",
    bottom: "15px",
    fontSize: "14px",
    fontWeight: "500"
}));
const minutesInHour = 60;
export const minVideoLengthInMinutes = 1;
export const maxVideoLengthInMinutes = 3;
export const maxFileSize = bytesIn1GB * 2;
const wrongFileErrorMessage = "There’s something wrong with this video file. Please upload a different file.";
const incorrectFileTypeErrorMessage = "This file is not a video file. Please upload a valid video file.";
export const FeedbackUploadZone = ({
   showControls = false,
   showReplaceVideo = true,
   videoIssueReason = "",
   uploadVideoPercentage,
   uploadVideoInBackgroundCB,
   abortBackgroundUploadingCB,
   previewUrl,
   updatePreviewUrl,
   selectAnotherVideoProp,
}: FeedbackUploadZoneProps): JSX.Element => {
    let inputRef = useRef<HTMLInputElement>(null)
    const dispatch = useAppDispatch()
    const uploadStatus = useAppSelector<MediaUploadStatus>((state: RootState) => state.media.uploadStatus);
    const uploadFilename = useAppSelector<UploadFilename|undefined>((state: RootState) => state.media.uploadFilename)
    const uploadResponse = useAppSelector<MediaUploadResponse|undefined>((state: RootState) => state.media.uploadResponse)

    const [selectVideoError, setSelectVideoError] = useState<string>("");
    const [showAbortConfirmation, setShowAbortConfirmation] = useState<boolean>(false);
    const [droppedFile, setDroppedFile] = useState<File|undefined>(undefined);
    const [creatingUrlPromise, setCreatingUrlPromise] = useState<any>(null);


    const createS3Url = useCallback(() => {
        if (!uploadResponse && droppedFile !== undefined && uploadFilename !== undefined) {
            dispatch(updateUploadStatus(MediaUploadStatus.CreatingUrl));
            setCreatingUrlPromise(dispatch(getS3urlsThunk({ filename: uploadFilename.cdnFilename } as S3urlsRequest)));
        }
    }, [uploadResponse, droppedFile, uploadFilename, dispatch]);
    const updateDroppedFile = (file: File) => {
        setDroppedFile(file)
        const cdnFilename = file.name.replace(/[^a-z0-9.]/gi, '').toLowerCase()
        dispatch(updateFilename({
            original: file.name,
            cdnFilename: Date.now() + cdnFilename,
            localFilePath: URL.createObjectURL(file),
            fileType: file.type,
        }))
    }

    useEffect(() => {
        if (uploadStatus === MediaUploadStatus.CreatedUrl && droppedFile) {
            uploadVideoInBackgroundCB(droppedFile);
        }
        if (uploadStatus === MediaUploadStatus.Aborted && abortBackgroundUploadingCB) {
            abortBackgroundUploadingCB();
        }
    },[uploadStatus]) // eslint-disable-line

    const validateMetadata = (fileSize: number, videoDuration: number): boolean => {
        let error = "";
        if ((videoDuration - 0.5) > (getCFMaxLength() || maxVideoLengthInMinutes)) {
            error = `Your video cannot be more than ${getCFMaxLength() || maxVideoLengthInMinutes} minutes`;
        }
        if (videoDuration < minVideoLengthInMinutes) {
            error = `Your video cannot be less than ${minVideoLengthInMinutes} minute`;
        }
        if (fileSize > (getCFMaxFileSize() || maxFileSize)) {
            error = `Your video should not exceed ${formatBytes(getCFMaxFileSize()|| maxFileSize)}`;
        }
        if (error) {
            setSelectVideoError(error);
            dispatch(updateUploadStatus(MediaUploadStatus.Fail));
            return false;
        }
        return true;
    }
    const fileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget && event.currentTarget.files && event.currentTarget.files[0]) {
            const file = event.currentTarget.files[0];
            if (!file.type || !file.type.includes('video')) {
                setSelectVideoError(incorrectFileTypeErrorMessage);
                dispatch(updateUploadStatus(MediaUploadStatus.Fail));
                Logger.log(`Invalid file attempt upload: file: ${file?.name}, type: ${file?.type}`);
                return;
            }
            const video = document.createElement('video');
            video.onloadedmetadata = () => {
                window.URL.revokeObjectURL(video.src);
                if (!isFinite(video.duration) || isNaN(video.duration)) {
                    setSelectVideoError(wrongFileErrorMessage);
                    dispatch(updateUploadStatus(MediaUploadStatus.Fail));
                    Logger.log(`Invalid video duration value: ${video.duration}, file: ${file?.name}, type: ${file?.type}`);
                    return;
                } else {
                    dispatch(resetVideo())
                    setSelectVideoError("");
                }
                if (validateMetadata(file.size, video.duration / minutesInHour)) {
                    updateDroppedFile(file);
                }
            }
            video.onerror = (event) => {
                console.log(event)
                setSelectVideoError(wrongFileErrorMessage);
                dispatch(updateUploadStatus(MediaUploadStatus.Fail));
                Logger.log(`Failed to load video: ${video.src}, file: ${file?.name}, type: ${file?.type}`);
            };
            video.src = URL.createObjectURL(event.currentTarget.files[0]);
        }
    }

    useEffect(() => {
        if (uploadStatus === MediaUploadStatus.Success || uploadStatus === MediaUploadStatus.Fail) {
            setDroppedFile(undefined);
        }
        const fileType = droppedFile ? getFileType(droppedFile) : null;
        if (uploadStatus === MediaUploadStatus.Fail && uploadFilename && droppedFile && fileType) {
            Analytics.videoUploadFailure(droppedFile.size, fileType);
        }
    }, [uploadStatus, uploadFilename, droppedFile]);

    useEffect(()=> {
        createS3Url();
    }, [createS3Url])

    const resetSelectedFile = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        const inputElement = event.target as HTMLInputElement;
        inputElement.value = '';
    }
    const selectVideo = useCallback(() => {
        Analytics.selectVideo();
        if (inputRef.current && !droppedFile) {
            inputRef.current.click();
        }
    }, [inputRef, droppedFile]);
    const selectAnotherVideo = useCallback(() => {
        if (selectAnotherVideoProp) {
            selectAnotherVideoProp();
            return;
        }
        selectVideo();
    }, [selectAnotherVideoProp, selectVideo]);
    const uploadVideo = useCallback(() => {
        selectVideo();
    }, [selectVideo]);
    const reupload = () => {
        if (uploadStatus !== MediaUploadStatus.Fail || !droppedFile) {
            return;
        }

        if (!uploadResponse) {
            createS3Url();
            return;
        }

        uploadVideoInBackgroundCB(droppedFile);
    }
    const onCancelUploading = () => {
        Analytics.videoUploadUserCancel(droppedFile!.size);
        setDroppedFile(undefined);
        setShowAbortConfirmation(false);
        dispatch(resetVideo());
        updatePreviewUrl("");
        if (creatingUrlPromise && uploadStatus === MediaUploadStatus.CreatingUrl) {
            creatingUrlPromise.abort();
            setCreatingUrlPromise(null);
        }
        dispatch(updateUploadStatus(MediaUploadStatus.Aborted));
    }

    return (
        <div>
            <input
                id="the-file-input"
                type="file"
                ref={inputRef}
                onChange={fileInputChange}
                onClick={resetSelectedFile}
                accept="video/*"
            />
            {uploadStatus === MediaUploadStatus.None && (
                <>
                    {videoIssueReason && <Box sx={{ display: "flex", gap: "10px", mb: "20px"}}>
                        <SyncProblem color="warning" sx={{mt: "5px"}}/>
                        <SubtitleErrorTypography>{videoIssueReason}</SubtitleErrorTypography>
                    </Box>}
                    <AddVideoBox
                        boxTitle="Select Video"
                        boxSubtitle={`${minVideoLengthInMinutes} to ${getCFMaxLength() || maxVideoLengthInMinutes} minutes. Maximum ${formatBytes(getCFMaxFileSize() || maxFileSize)}`}
                        sx={{minHeight: "82px", justifyContent: "center"}}
                        onAddNew={uploadVideo}
                    />
                </>
            )}
            {(uploadStatus === MediaUploadStatus.CreatingUrl || uploadStatus === MediaUploadStatus.Posting) && (
                showAbortConfirmation ?
                    (
                        <AbortConfirmation>
                            <AbortConfirmationTypography color="secondary">Cancel video upload?</AbortConfirmationTypography>
                            <StyledRetryButton variant="outlined" color="secondary" startIcon={<Refresh />} sx={{mt: "10px"}} onClick={() => setShowAbortConfirmation(false)}>Keep Uploading</StyledRetryButton>
                            <ErrorButton color="secondary" variant="text" onClick={onCancelUploading}>Yes, cancel upload</ErrorButton>
                        </AbortConfirmation>
                    ) : (
                        <VideoUploading>
                            <Box sx={{ display: "flex", gap: "10px"}}>
                                <Sync/>
                                <Typography sx={{fontWeight: "500"}}>
                                    Uploading your video...{uploadVideoPercentage !== null && `${uploadVideoPercentage}%`}
                                </Typography>
                            </Box>
                            {abortBackgroundUploadingCB && (
                                <IconButton size="small" onClick={() => setShowAbortConfirmation(true)}><StyledCloseIcon/></IconButton>
                            )}
                        </VideoUploading>
                    )
            )}
            {(uploadStatus === MediaUploadStatus.Fail || uploadStatus === MediaUploadStatus.Aborted) && (
                <>
                    <FailedBox>
                        <SyncProblem color="warning"/>
                        <Typography color="warning.main">Upload failed</Typography>
                        {!selectVideoError && <StyledRetryButton variant="outlined" color="secondary" startIcon={<Refresh />} sx={{mt: "10px"}} onClick={reupload}>Try Again</StyledRetryButton>}
                        <StyledRetryButton color="secondary" startIcon={<Add />} onClick={selectAnotherVideo}>Select Another Video</StyledRetryButton>
                    </FailedBox>
                    {selectVideoError && <Typography color="warning.main" variant="caption" sx={{fontWeight: "500"}}>{selectVideoError}</Typography>}
                </>
            )}
            {(uploadStatus === MediaUploadStatus.Success && previewUrl) && (
                <PreviewBox>
                    <video src={`${previewUrl}#t=0.001`} className="uploaded-preview-video" controls={showControls} playsInline/>
                    {showReplaceVideo && <ReplaceButton
                        variant="contained"
                        disableElevation
                        color="secondary"
                        onClick={selectVideo}
                    >
                        Replace video
                    </ReplaceButton>}
                </PreviewBox>
            )}
        </div>
    )
}
