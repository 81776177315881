import {createTheme} from "@mui/material";

declare module '@mui/material/styles' {
    interface Palette {
        olive: Palette['primary'];
    }

    interface PaletteOptions {
        olive?: PaletteOptions['primary'];
    }
}

export const ffctheme = createTheme({
    custom: {
        name: 'ffc',
        academyName: 'FFC Academy',
        academyCommonName: 'Fulham FC Academy',
        academyCommonShortName: 'Fulham FC',
        isWhiteLabelled: true,
        stripe: {
            colorPrimary: "#121212", //this.palette.secondary.main,
            colorBackground: "#FFFFFF", //this.custom.isWhiteLabelled ? this.custom.mainBackgroundColor : this.custom.mainBackgroundColor,
            colorText: "#212121", //this.custom.isWhiteLabelled ? this.custom.stripePaymentText : this.palette.text.primary,
            colorDanger: "#FF6E6E", //this.palette.warning.main,
            colorWarning: "#FF6E6E", //this.palette.warning.main,
            fontFamily: [
                'BrownStd',
                'Arial',
                'sans-serif',
            ].join(','), //this.typography.fontFamily,
            labelFontFamily: [
                'BrownStd',
                'Arial',
                'sans-serif',
            ].join(','), //this.typography.fontFamily,
            fontSizeBase: "16px",
            fontWeightNormal: "500",
            spacingUnit: "5px",
            borderRadius: "0",
            borderColor: "rgb(33,33,33)",
        },
        wl: {
            supportEmail: "support@ffc.viaacademy.com",
            termsURI: "https://www.viaacademy.com/ffc/terms",
            privacyURI: "https://www.viaacademy.com/ffc/privacy",
            faqURI: "https://www.viaacademy.com/ffc/faq",
            globalBorderRadius: "0px",
            splashPageButtonBgColor: "#212121",
            signIn: {
                buttonBackgroundColor: "#DA322F",
                buttonBorderColor: "#212121",
            },
            accountPages: {
                accountManagementURI: "https://login.fulhamfc.com"
            },
            bottomCTA: {
                bgColor: "#FFFFFF",
                buttonBackgroundColor: "#DB3231",
                buttonColor: "#FFFFFF"
            },
            purchaseStepHeader: {
                backgroundColor: '#000000',
                color: "#FFFFFF",
                liveProgressTextColor: "#FFFFFF",
                progressBackgroundColor: "#999999"
            },
            improvementStep: {
                backgroundColor: '#DB3231',
                color: '#000000',
                buttonTextColor: '#FFFFFF',
                buttonHoverColor: '#f3a1a0'
            },
            bottomAppTabs: {
                backgroundColor: "#000000",
                selectedColor: "#FFFFFF",
                color: "#012448",
                borderTop: "#B3BDC8"
            },
            surveyPoll: {
                optionColor: "#DA322F",
                voteStatusColor: "#000000",
                chosenColor: "#DA322F",
            },
            aiCoach: {
                coachAvatarUri: "/ai_coach/ffc_coach_avatar.png",
                coachBubbleBackgroundColor: "#DA322F",
                coachBubbleTextColor: "#FFFFFF",
                coachBubbleChoiceTextColor: "#212121",
                playerAvatarUri: "/ai_coach/ffc_coach_avatar.png",
                playerBubbleBackgroundColor: "#FFFFFF",
                playerBubbleTextColor: "#212121",
                playerQuestionChoiceDelimiterColor: "#EBEBEB"
            }
        },
        headerBrandTitleColor: '#FFFFFF',
        headerBrandTitleText: '',
        // brandTitleLogo: "/wl/fcc_header_title_logo.png",
        brandTitleLogo: "/club_logos/fh_logo.png",
        brandAcademyLogoVertical: "/club_logos/FFCA_Logo.png",
        brandAcademyLogoHorizontal: "/club_logos/FFCA_Hoz_Black.png",
        brandAcademyLogoHorizontalDarkMode: "/club_logos/FFCA_Hoz_White.png",
        mainBackgroundColor: "#FFFFFF",
        contentFeedBackgroundColor: "#F1F1F1",
        stripePaymentText: '#DB3231',
        headerBrandTitleBackgroundColor: '#000000',
        brandCoverPhoto: "/wl/ffc_gateway_cover.jpeg",
        uploadBoxColor: "#F1F1F1",
        communityPostTextColor: "#000000",
        communityLikertTextColor: "#000000",
        communityPollButtonColor: "#DB3231",
        darkerPrimaryColor: "#212121",
        subscriptionChoiceBorderColor: "#DB3231",
        monthlyProSubscriptionSKU: "PS-FFC-M",
        yearlyProSubscriptionSKU: "PS-FFC-Y",
        coachFeedbackPromoPoster: "/club_posters/FFCA_feedback_poster.png",
        coachFeedbackVideoSampleLink: "https://via.kz-cdn.net/static/samples/ffc_sample.mp4",
        coachFeedbackVideoSampleThumbnailLink: "https://via.kz-cdn.net/static/samples/ffc_sample_thumbnail.jpg",
        submissionVideoSampleLink: "https://via.kz-cdn.net/static/samples/video_guidelines.mp4",
        submissionVideoSampleThumbnailLink: "https://via.kz-cdn.net/static/samples/video_guidelines_thumbnail.jpg",
        newsFeedIndexSlug: "ffc-news-feed-index",
        trainingFeedIndexSlug: "ffc-training-videos-index",
        proVideoLabelColor: "#DA322F",
        freeVideoLabelColor: "#101010",
        newButtonColor: "#DB4633",
    },
    typography: {
        fontFamily: [
            'BrownStd',
            'Arial',
            'sans-serif',
        ].join(','),
        button: {
            textTransform: "none"
        }
    },
    palette: {
        mode: 'light',
        primary: {
            main: '#DB3231',
            contrastText: '#081111',
        },
        secondary: {
            main: '#121212',
            contrastText: '#FFFFFF',
        },
        warning: {
            main: '#FF6E6E',
            contrastText: '#FF6E6E',
        },
        error: {
            main: '#FF6E6E',
            contrastText: '#FF6E6E',
        },
        info: {
            main: '#2196f3',
            contrastText: '#DAB870',
        },
        text: {
            primary: '#212121',
            secondary: '#AAAAAA',
        },
        grey: {
            100: '#F1F1F1',
            200: '#E8E8E8',
            300: '#D2D2D2',
            500: '#AAAAAA',
            600: '#A9A9A9',
            700: '#616161',
            800: '#414141',
            900: '#121212'
        },
        common: {
            black: '#081111'
        },
        background: {
            paper: '#F1F1F1'
        },
        divider: "#222222",
        olive: {
            main: '#162121',
        }
    },
    // components: {
    //     MuiTypography: {
    //         defaultProps: {
    //             variantMapping: {
    //                 h1: 'h2',
    //                 h2: 'h2',
    //                 h3: 'h2',
    //                 h4: 'h2',
    //                 h5: 'h2',
    //                 h6: 'h2',
    //                 subtitle1: 'h2',
    //                 subtitle2: 'h2',
    //                 body1: 'span',
    //                 body2: 'span',
    //             },
    //         },
    //     },
    // },
})
