import React, {useEffect, useRef} from "react";
import {Box, Button, Divider, styled, Typography, useTheme} from "@mui/material";
import {ArrowBack} from "@mui/icons-material";
import {ButtonVariant} from "../constants/styles";
import {ColumnBox} from "./styles";
import Portal from '@mui/material/Portal';
import {SignInFooterComponent} from "./text-field/SignInFooterComponent";


const StyledLayoutContainer = styled(Box)(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    flex: "1 1",
    backgroundColor: theme.custom.isWhiteLabelled ? theme.custom.wl.bottomCTA.bgColor : "#121212",
}));
const StyledHead = styled(Box)(() => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "baseline",
}));
const StyledTitle = styled(Typography)(() => ({
    fontSize: "20px",
    fontWeight: "700",
}));
const StyledSubtitle = styled(Typography)(({theme}) => ({
    fontSize: "14px",
    color: theme.palette.text.primary,
    padding: "0 16px",
}));
const ErrorTypography = styled(Typography)(({theme}) => ({
    textTransform: "none",
    fontSize: "14px",
    fontWeight: "500",
    color: theme.palette.warning.main,
    textAlign: "center",
    marginTop: "15px"
}));
const StyledActions = styled(Box)(({theme}) => ({
    flex: "0 0 auto",
    width: "100%",
    fontSize: "18px",
    color: theme.palette.text.primary,
    backgroundColor: theme.custom.wl.bottomCTA.bgColor,
    position: "sticky",
    bottom: theme.custom.isWhiteLabelled ? 60 : 0,
    left: 0,
    zIndex: 2,
}));
const ActionGroup = styled(Box)(() => ({
    display: "flex",
    flexDirection: "row",
    margin: "8px 0",
    gap: "20px",
}));
const StyledCTA = styled(Button)(({theme}) => ({
    flexGrow: "1",
    borderRadius: "24px",
    fontSize: "18px",
    fontWeight: "600",
    height: "48px",
    textTransform: "none",
    "&:disabled": {
        color: theme.palette.common.black,
    },
    ...(theme.custom.isWhiteLabelled ? {
            backgroundColor: theme.custom.newButtonColor,
            color: theme.custom.wl.bottomCTA.buttonColor
        } : {} ),
    [theme.breakpoints.down("sm")]: {
        padding: "6px",
    },
}));
const BackButton = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    borderRadius: "24px",
    fontSize: "18px",
    fontWeight: "600",
    height: "48px",
    padding: "0 25px",
    textTransform: "none",
}));

interface ScreenLayoutProps {
    title: string | JSX.Element;
    screenInfo?: JSX.Element;
    subtitle?: string | JSX.Element;
    displayCta?: boolean;
    ctaTitle: string | JSX.Element;
    ctaVariant?: ButtonVariant;
    ctaDisabled?: boolean;
    ctaUnderSection?: React.ReactElement;
    bottomSection?: JSX.Element;
    errorMessage?: string;
    onAction: () => void;
    onBack?: () => void;
    hideBackButton?: boolean;
    onCancel?: () => void;
    children: JSX.Element;
    contentFeedBackgroundColor?: string;
    contentMarginTop?: string;
    contentMarginBottom?: string;
    contentPadding?: string;
    headerMarginTop?: string;
    headerPadding?: string;
    tabHash?: string;
    displaySubGateway?: boolean
    hideSubWall?: ()=> void
    reloadSignIn?: ()=> void
}

export const ScreenLayout = (props: ScreenLayoutProps): JSX.Element => {
    const displayCta = props.displayCta === undefined ? true : props.displayCta
    const ctaVariant = props.ctaVariant || ButtonVariant.contained
    const ctaDisabled = !!props.ctaDisabled
    const theme = useTheme()
    const mainColor = props.contentFeedBackgroundColor || theme.custom.contentFeedBackgroundColor || theme.custom.mainBackgroundColor
    const containerRef = useRef<HTMLDivElement>(null);
    const titleHasTypeString = typeof props.title === "string";

    useEffect(() => containerRef.current?.scrollTo(0,0), []);
    return (
        <StyledLayoutContainer>
            <ColumnBox
                sx={{
                    backgroundColor: mainColor,
                    flex: 1,
                    marginTop: props.headerMarginTop || "0px",
                    padding: "0px"
                }}
                ref={containerRef}
            >
                <StyledHead sx={{backgroundColor: theme.custom.isWhiteLabelled ? "#FFFFFF" : "#121212", padding: props.headerPadding || "8px 16px"}}>
                    <>
                        {titleHasTypeString ? (
                            <>
                                <StyledTitle className="use-header-font" color="secondary" sx={{ textTransform: 'uppercase' }}>{props.title}</StyledTitle>
                                {props.title === "Training" && props.displayCta && (
                                    <Button
                                        sx={{
                                            backgroundColor: theme.custom.wl.bottomCTA.buttonBackgroundColor,
                                            color: theme.custom.wl.bottomCTA.buttonColor,
                                            borderRadius: "8px",
                                            '&:hover': {
                                                backgroundColor: theme.custom.darkerPrimaryColor,
                            }
                                        }}
                                        onClick={props.onAction}
                                    >
                                        <Typography className="use-header-font">SUBSCRIBE</Typography>
                                    </Button>
                                )}
                            </>
                        ) : (
                            props.title
                        )}
                        {props.screenInfo}
                    </>
                </StyledHead>
                {props.subtitle && <StyledSubtitle>{props.subtitle}</StyledSubtitle>}
                <Box
                    sx={{
                        mt: props.contentMarginTop || "0",
                        padding: props.contentPadding || "0 8px",
                        paddingBottom:
                            theme.custom.isWhiteLabelled
                                ? displayCta
                                    ? (props.contentMarginBottom || "30px")
                                    : (props.contentMarginBottom || "16px")
                                : "0"
                    }}
                >{props.children}</Box>
            </ColumnBox>
            {props.bottomSection}
            {/*{ !theme.custom.isWhiteLabelled && <Footer /> }*/}
            {props.displaySubGateway &&
                <StyledActions>
                    <Portal>
                        <Box sx={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            }} 
                            onClick={()=> props.onAction()}/>
                    </Portal>
                    <SignInFooterComponent
                        hideSubWall={props.hideSubWall}
                        reloadSignIn={props.reloadSignIn}
                        onAction={props.onAction}
                    />
                    {/*<Box sx={{display: "flex", flexDirection: "column", zIndex: 1}} className="sticky-footer">*/}
                    {/*    { checkIsAnonymousUser() ?*/}
                    {/*        <GuestUpgradeGateway*/}
                    {/*            closeFn={*/}
                    {/*            props.hideSubWall ?*/}
                    {/*                props.hideSubWall*/}
                    {/*                : ()=>{}*/}
                    {/*            }*/}
                    {/*            reloadSignIn={props.reloadSignIn}/>*/}
                    {/*        : <><SubscriptionWall/><Button sx={{padding: "10px 0"}} variant="text" onClick={()=> props.onAction()}>Not now</Button></>*/}
                    {/*    }*/}
                    {/*</Box>*/}
                </StyledActions>
            }
            {displayCta && <StyledActions>
                <>
                { theme.custom.name !== "qpr" && <Divider/> }
                    <Box sx={{display: "flex", flexDirection: "column", p: "0 10px"}} className="sticky-footer">
                        {props.errorMessage && <ErrorTypography>{props.errorMessage}</ErrorTypography>}
                        <ActionGroup>
                            {!!props.onCancel && <BackButton variant="text" color="secondary" onClick={props.onCancel}>Cancel</BackButton>}
                            {!!props.onBack && !props.hideBackButton && !props.onCancel && <BackButton variant="text" color="secondary" startIcon={<ArrowBack/>} onClick={props.onBack}>Back</BackButton>}
                            <StyledCTA
                                variant={ctaVariant}
                                color={ctaVariant === ButtonVariant.outlined ? 'secondary' : "primary"}
                                fullWidth
                                disabled={ctaDisabled}
                                onClick={props.onAction}
                            >
                                {props.ctaTitle}
                            </StyledCTA>
                        </ActionGroup>
                        {props.ctaUnderSection}
                    </Box>
                </>
            </StyledActions>}
        </StyledLayoutContainer>
    );
}
