import {getGotoUrl, isRedirectAfterLogin, SiteSections} from "../../util/storage";
import React, {useEffect} from "react";
import {Box, Link, styled, Typography, useTheme} from "@mui/material";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {Loader} from "../../shared/Loader";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import AutoAwesomeMotionOutlinedIcon from "@mui/icons-material/AutoAwesomeMotionOutlined";
import {Links} from "../../constants/links";
import {Analytics} from "../../lib/analytics";
import {useNavigate} from "react-router-dom";


const StyledSectionBox = styled(Box)(({theme}) => ({
    margin: "0 0 16px",
    borderRadius: theme.custom.wl.globalBorderRadius,
    display: "flex",
    flexDirection: "row",
}));

const StyledSectionIcon = styled(Box)(({theme}) => ({
    backgroundColor: theme.custom.wl.splashPageButtonBgColor,
    border: "1px solid " + theme.custom.wl.splashPageButtonBgColor,
    borderTopLeftRadius: theme.custom.wl.globalBorderRadius,
    borderBottomLeftRadius: theme.custom.wl.globalBorderRadius,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "120px",
}));

const StyledSectionDesc = styled(Box)(({theme}) => ({
    borderTop: "1px solid " + theme.custom.wl.splashPageButtonBgColor,
    borderBottom: "1px solid " + theme.custom.wl.splashPageButtonBgColor,
    color: theme.custom.darkerPrimaryColor,
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    flexGrow: "1",
    padding: "8px",
}));

const StyledSectionChevron = styled(Box)(({theme}) => ({
    borderTop: "1px solid " + theme.custom.wl.splashPageButtonBgColor,
    borderBottom: "1px solid " + theme.custom.wl.splashPageButtonBgColor,
    borderRight: "1px solid " + theme.custom.wl.splashPageButtonBgColor,
    borderTopRightRadius: theme.custom.wl.globalBorderRadius,
    borderBottomRightRadius: theme.custom.wl.globalBorderRadius,
    color: theme.custom.wl.splashPageButtonBgColor,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // minWidth: "30px",
}));

export interface GuestGatewayComponentProps {
    onInsightClick: () => void
    onFeedbackClick: () => void
    onTrainingClick: () => void
    chosen: SiteSections|null
}

export const GuestGatewayComponent = (props: GuestGatewayComponentProps): React.ReactElement => {
    const theme = useTheme()
    const coverHeight: string = "250px"
    const navigate = useNavigate()

    useEffect(()=> {
        if(isRedirectAfterLogin()){
            navigate(getGotoUrl())
        }else{
            console.log("homeView happened Now")
            Analytics.homeView();
        }
    },[navigate])

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    height: coverHeight, // Set the desired height for the Box
                    backgroundImage: 'url(' + window.location.origin + theme.custom.brandCoverPhoto + ')', // Replace with your image URL
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white', // Text color for visibility
                }}
            >
                <img
                    src={theme.custom.brandAcademyLogoVertical ? theme.custom.brandAcademyLogoVertical : theme.custom.brandTitleLogo}
                    style={{
                        marginTop: !!theme.custom.brandAcademyLogoVertical ? "275px" : coverHeight,
                        height: (!!theme.custom.brandAcademyLogoVertical ? "100px" : "80px")
                }}
                     alt="Academy Cover"/>
                {/*<img*/}
                {/*    src={theme.custom.brandAcademyLogoVertical ? theme.custom.brandAcademyLogoVertical : theme.custom.brandTitleLogo}*/}
                {/*    style={{*/}
                {/*        zIndex: 100,*/}
                {/*        marginTop: coverHeight,*/}
                {/*        height: (!!theme.custom.brandAcademyLogoVertical ? "80px" : "64px")*/}
                {/*    }} alt="Academy Cover"/>*/}
            </Box>
            <Box sx={{
                padding: !!theme.custom.brandAcademyLogoVertical ? "84px 16px 32px" : "64px 16px 32px",
                color: theme.custom.darkerPrimaryColor
            }}>
                <Typography sx={{
                    fontSize: "22px",
                    fontWeight: 600,
                    lineHeight: "21px",
                    textAlign: "center",
                }}>Welcome to the {theme.custom.name === "ffc" ? "Fulham FC " : ""}Virtual Academy</Typography>
                <Typography sx={{
                    margin: "32px 0 24px",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "24px",
                    textAlign: "center",
                }}>
                    What are you interested in?
                </Typography>
                <Box sx={{
                    // padding: "64px 8px 0",
                    color: theme.custom.darkerPrimaryColor
                }}>
                    <StyledSectionBox
                        sx={{
                            "&:hover": {
                                cursor: !props.chosen ? "pointer" : "default"
                            }
                        }}
                        onClick={props.onInsightClick}
                    >
                        <StyledSectionIcon>
                            <FeedOutlinedIcon style={{ color: "white", margin: "8px 8px 0"}}/>
                            <span style={{margin: "-6px 8px 8px", color: 'white'}}>Insights</span>
                        </StyledSectionIcon>
                        <StyledSectionDesc><Typography>Behind-the-scenes updates from our coaches</Typography></StyledSectionDesc>
                        <StyledSectionChevron>{ !props.chosen ?
                            <ChevronRightIcon/>
                            : props.chosen === "Insights" ?
                                <Loader size={24}/>
                                :
                                <ChevronRightIcon sx={{color: theme.custom.wl.splashPageButtonBgColor}}/>
                        }</StyledSectionChevron>
                    </StyledSectionBox>

                    <StyledSectionBox
                        sx={{
                            "&:hover": {
                                cursor: !props.chosen ? "pointer" : "default"
                            }
                        }}
                        onClick={props.onFeedbackClick}
                    >
                        <StyledSectionIcon>
                            <PlayCircleOutlineIcon style={{ color: "white", margin: "8px 8px 0"}}/>
                            <span style={{margin: "-6px 8px 8px", color: 'white'}}>Feedback</span>
                        </StyledSectionIcon>
                        <StyledSectionDesc><Typography>Personalised feedback and analysis on your videos</Typography></StyledSectionDesc>
                        <StyledSectionChevron>{ !props.chosen ?
                            <ChevronRightIcon/>
                            : props.chosen === "Feedback" ?
                                <Loader size={24}/>
                                :
                                <ChevronRightIcon sx={{color: theme.custom.wl.splashPageButtonBgColor}}/>
                        }</StyledSectionChevron>
                    </StyledSectionBox>

                    <StyledSectionBox
                        sx={{
                            "&:hover": {
                                cursor: !props.chosen ? "pointer" : "default"
                            }
                        }}
                        onClick={props.onTrainingClick}
                    >
                        <StyledSectionIcon>
                            <AutoAwesomeMotionOutlinedIcon style={{ color: "white", margin: "8px 8px 0"}}/>
                            <span style={{margin: "-6px 8px 8px", color: 'white'}}>Training</span>
                        </StyledSectionIcon>
                        <StyledSectionDesc><Typography>Academy training videos, plus interact with your Academy Intelligence Coach</Typography></StyledSectionDesc>
                        <StyledSectionChevron>{ !props.chosen ?
                            <ChevronRightIcon/>
                            : props.chosen === "Training" ?
                                <Loader size={24}/>
                                :
                                <ChevronRightIcon sx={{color: "white"}}/>
                        }</StyledSectionChevron>
                    </StyledSectionBox>
                    { theme.custom.isWhiteLabelled && <Typography sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                        lineHeight: "24px",
                        textAlign: "center",
                        marginTop: "20px"
                    }}>VIA Academy is the operator of the {theme.custom.academyCommonShortName} Virtual Academy.</Typography>
                    }
                    <Typography sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                        lineHeight: "24px",
                        textAlign: "center",
                    }}>By continuing you agree to our <Link color="secondary" underline="always" target="_blank" href={theme.custom.isWhiteLabelled ? theme.custom.wl.termsURI : Links.terms}>Terms</Link> and <Link color="secondary" underline="always" target="_blank" href={theme.custom.isWhiteLabelled ? theme.custom.wl.privacyURI : Links.privacy}>Privacy Policy</Link> </Typography>
                </Box>
            </Box>
        </>)

}
