import {Outlet} from "react-router-dom";
import React from "react";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";
import {getUserProfileThunk, UpdateTermsVersionRequest, ViaUserProfile} from "../../../../features/user/userSlice";
import {RootState} from "../../../../redux/store";
import {checkIsAnonymousUser} from "../../../../util/storage";
import {useTheme} from "@mui/material/styles";
import {getViaUserProfile, updateUserProfile} from "../../../../services/api";

const ongoingAcceptedTermsVersion = 4;
// const CTAButton = styled(Button)(({theme}) => ({
//     borderRadius: "24px",
//     height: "48px",
//     marginTop: "10px",
//     fontSize: "18px",
//     fontWeight: "600",
//     "&:disabled": {
//        backgroundColor: theme.palette.text.primary,
//         color: theme.palette.grey[700]
//     }
// }));
export const AcceptTerms = () => {
    const theme = useTheme()
    const dispatch = useAppDispatch();
    const profile = useAppSelector<ViaUserProfile|undefined>((state: RootState) => state.user.profile);
    // const [ trigger, {isLoading, data, error} ] = profileServiceApi.endpoints.updateProfile.useLazyQuery();
    // const [contentRead, setContentRead] = useState(false);


    // useEffect(() => {
    //     if (data) {
    //         dispatch(getUserProfileThunk());
    //     }
    // }, [data, dispatch]);

    // const handleAccept = () => {
    //     if (contentRead) {
    //         trigger({user: { accepted_terms_version: ongoingAcceptedTermsVersion }});
    //     }
    // };

    if(theme.custom.isWhiteLabelled){
        return <Outlet/>
    }

    if (((profile?.user.accepted_terms_version || 0) < ongoingAcceptedTermsVersion) && !checkIsAnonymousUser() ) {
        const updateUserTerms = async () => {
            const emptyProfile: ViaUserProfile = await getViaUserProfile()

            // other default fields are set by the backend on creation.
            let webDefaults: UpdateTermsVersionRequest =
                {
                    uuid: emptyProfile.uuid,
                    user:
                        {
                            accepted_terms_version: 4,
                        },
                } as UpdateTermsVersionRequest

            await updateUserProfile(webDefaults)
            dispatch(getUserProfileThunk())
        }
        updateUserTerms().then()

        return (<Outlet/>)

        // return (
        //     <BasicModal
        //         blockClickOutside
        //         actionContent={
        //             <>
        //                 <CTAButton
        //                     fullWidth
        //                     disabled={!contentRead || isLoading}
        //                     variant="contained"
        //                     endIcon={isLoading && <CircularProgress size={16} sx={{ml: "5px"}} color="secondary"/>}
        //                     onClick={handleAccept}
        //                 >
        //                     Accept and Continue
        //                 </CTAButton>
        //                 {error && <ErrorTypography>{generalMessage}</ErrorTypography>}
        //             </>
        //         }
        //     >
        //         <AcceptTermsModalContent setContentRead={setContentRead}/>
        //     </BasicModal>
        // );
    }
    return <Outlet />;
}
