import {Amplify, Auth, Hub} from "aws-amplify";
import {AppConfig} from "../config";
import {SocialProvider} from "../constants/social";
import {
    CognitoAccessToken,
    CognitoIdToken,
    CognitoRefreshToken,
    CognitoUser, CognitoUserPool,
    CognitoUserSession
} from "amazon-cognito-identity-js";
import {CognitoUserGroup} from "../constants/cognito";
import {isTokenExpired} from "../util/jwt";
import {
    checkIsWhiteLabelledByPUFC,
    checkIsWhiteLabelledByQPR,
    clearIsAnonymousUser, getAttemptedPage, getGotoUrl, getItem, getWhiteLabelClubName,
    setIsAnonymousUser, setItem
} from "../util/storage";
import {getUserProfileThunk} from "../features/user/userSlice";
import { store, AppDispatch } from '../redux/store';
import {routes} from "../constants/routes";
import {getNavigate} from "./navigationService";


Amplify.configure({
    Auth: {
        region: AppConfig.COGNITO.REGION,
        userPoolId: AppConfig.COGNITO.USER_POOL_ID,
        userPoolWebClientId: AppConfig.COGNITO.WEB_CLIENT_ID,
        authenticationFlowType: "USER_SRP_AUTH",
        oauth: {
            domain: AppConfig.COGNITO.OAUTH_CUSTOM_DOMAIN,
            scope: [
                "email",
                "profile",
                "openid",
                "aws.cognito.signin.user.admin",
            ],
            redirectSignIn: AppConfig.COGNITO.OAUTH_LOGIN_REDIRECT,
            redirectSignOut: AppConfig.COGNITO.OAUTH_LOGIN_REDIRECT,
            responseType: "code"
        },
    },
});
const userPool = new CognitoUserPool({
    UserPoolId: AppConfig.COGNITO.USER_POOL_ID,
    ClientId: AppConfig.COGNITO.WEB_CLIENT_ID
});

let idToken = sessionStorage.getItem("idToken") || "";
let accessToken = sessionStorage.getItem("accessToken") || "";

export const signIn = async (email: string, password: string) => {
    // await signOut();
    return await Auth.signIn(email, password);
}

export const wlSocialSignIn = (themeName: string): void => {
    switch (themeName) {
        case "qpr": {
            socialSignIn(SocialProvider.KOREQPR)
            break;
        }
        case "pufc": {
            socialSignIn(SocialProvider.ClubcastPUFC)
            break;
        }
        case "ffc": {
            socialSignIn(SocialProvider.Google)
            break;
        }
        default: {
            socialSignIn(SocialProvider.Google)
        }
    }
}

export const clearPreventEmailSignInTimeOut = (): void => {
    sessionStorage.removeItem('preventEmailSignInUntil')
}

export const preventEmailSignInTimeOut = (): boolean => {
    const currentVal = sessionStorage.getItem('preventEmailSignInUntil')
    return (currentVal === null ? 0 : parseInt(currentVal)) > Date.now()
}

export const socialSignIn = (provider: string) => {
    // signOut();
    sessionStorage.setItem("preventEmailSignInUntil", (Date.now() + 300000) + "")
    Amplify.Auth.federatedSignIn({ provider });
}

export const getProvider = async () => {
    const jwtPayloadIndex = 1;
    const amplifyIdentityIndex = 0;
    if (!await getIdToken()) {
        return "";
    }
    const payload = JSON.parse(atob(idToken.split(".")[jwtPayloadIndex]));
    const provider: string | null = payload.identities[amplifyIdentityIndex]?.providerType || "";

    // @ts-ignore
    return Object.keys(SocialProvider).find(key => SocialProvider[key] === provider) ?? "";
}
const getLoggedInUser = async () => {
    try {
        return await Auth.currentAuthenticatedUser();
    } catch (error) {
        return null;
    }
}
const getCurrentSession = async () => {
    const loggedInUser = await getLoggedInUser();
    if (loggedInUser) {
        return Auth.currentSession();
    }
    return null;
}
export const setIdToken = async () => {
    try {
        const tokenData: CognitoUserSession = await Auth.currentSession();
        idToken = tokenData.getIdToken().getJwtToken();
        sessionStorage.setItem("idToken", idToken)
        console.log("idToken: ", idToken)
        accessToken = tokenData.getAccessToken().getJwtToken();
        console.log("accessToken: ", accessToken)
        sessionStorage.setItem("accessToken", accessToken)
    } catch (e) {}
};
export const getIdToken = async () => {
    if (!idToken.length) {
        return "";
    }
    if (isTokenExpired(idToken)) {
        await setIdToken();
    }

    return idToken;
}
export const roleHasAccess = async () => {
    const forbiddenRoles = Object.values(CognitoUserGroup);
    const userSession = await Auth.currentSession();
    if (!userSession) {
        return false;
    }
    const jwt = userSession.getIdToken().decodePayload();
    console.log("Checking if in AnonymousUsers group")
    if (jwt['cognito:groups'] && jwt['cognito:groups'].indexOf("AnonymousUsers") !== -1) {
        console.log("yes")
        setIsAnonymousUser()
        return true;
    }else{
        console.log("no")
        clearIsAnonymousUser()
    }
    if (jwt['cognito:groups']?.length === 1 && jwt['cognito:groups'][0] === "ai-coach-pre-release") {
        // temporary AI testing group
        return true;
    }
    if (!jwt['cognito:groups']?.length) {
        // In case no roles exists - it's a regular user
        return true;
    }

    return !jwt['cognito:groups'].some((group: string) => forbiddenRoles.includes(group));
}
export const resetToken = () => {
    idToken = "";
    sessionStorage.clear();
}

export const userInitiatedSignOut = async () => {
    handleUserSignOut()
    resetToken()

    Amplify.Auth.signOut({ global: true })
        .then(() => {
            console.log("Signed out globally");
        })
        .catch((error: any) => {
            console.error("Error signing out globally", error);
        });
}


export const signOut = async () => {
    localStorage.removeItem("anon_pw")
    localStorage.removeItem("anon_email")
    const userSession = await getCurrentSession();
    const cognitoUser = userPool.getCurrentUser();

    if (await getIdToken()) {
        resetToken();
    }
    if (!userSession || !cognitoUser) {
        return;
    }
    try {
        return cognitoUser.signOut();
    } catch (error) {
        console.log("Error signing out: ", error);
    }
}

/**
 * This function provides the ability to authenticate a user by previously obtained
 * ID and access tokens. has been extracted from this github issue:
 * https://github.com/aws-amplify/amplify-js/issues/8632

 */
export const authorizeByToken = async (
    authData: {
        idToken: string,
        accessToken: string,
        refreshToken: string,
    }): Promise<CognitoUser | any> => {

    const AccessToken = new
    CognitoAccessToken({
        AccessToken: authData.accessToken,
    });

    if (new Date(AccessToken.getExpiration() * 1000) < new Date()) {
        throw new Error("Tokens are expired");
    }

    const IdToken = new CognitoIdToken({
        IdToken: authData.idToken,
    });

    if (new Date(IdToken.getExpiration() * 1000) < new Date()) {
        throw new Error("Tokens are expired");
    }

    const RefreshToken = new
    CognitoRefreshToken({
        RefreshToken: authData.refreshToken,
    });

    const session = new CognitoUserSession({
        IdToken: IdToken,
        AccessToken: AccessToken,
        RefreshToken: RefreshToken,
    });

    try {
        const cognitoUser = new CognitoUser({
            Username: AccessToken.payload.username,
            Pool: userPool,
        });
        await cognitoUser.setSignInUserSession(session);
    } catch (e) {
        throw e;
    }
};

export const rehydrateSession = () => {
    const idToken = localStorage.getItem('idToken');
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');

    if (idToken && accessToken && refreshToken) {
        const cognitoUser = userPool.getCurrentUser();

        if (cognitoUser) {
            // Rehydrate the session using the tokens
            cognitoUser.refreshSession(
                new CognitoRefreshToken({ RefreshToken: refreshToken }),
                (err, session) => {
                    if (err) {
                        console.error('Failed to refresh session', err);
                    } else if (session) {
                        // Update the session in localStorage
                        handleLoginWithSession(session);
                        console.log('Session rehydrated', session);
                        setIdToken().then(()=>{
                            const dispatch: AppDispatch = store.dispatch;
                            dispatch(getUserProfileThunk())
                            const navigate = getNavigate();
                            navigate(
                                getAttemptedPage() === "/" ? routes.home
                                : getGotoUrl()
                            )
                        })
                        // Optionally, you can dispatch actions or set your app state here
                    }
                }
            );
        }
    }
};

export const checkForHydrationTokens = (): boolean => {
    return !!localStorage.getItem('idToken') && !!localStorage.getItem('accessToken') && !!localStorage.getItem('refreshToken')
}

const handleLoginWithSession = (session: CognitoUserSession | null) => {
    if (session) {
        localStorage.setItem('idToken', session.getIdToken().getJwtToken());
        localStorage.setItem('accessToken', session.getAccessToken().getJwtToken());
        localStorage.setItem('refreshToken', session.getRefreshToken().getToken());
    }
};

const handleUserSignOut = () => {
    localStorage.removeItem('idToken')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
};


const listener = (data: any) => {
    switch (data?.payload?.event) {
        case 'configured':
            console.log('the Auth module is configured');
            break;
        case 'signIn':
            clearPreventEmailSignInTimeOut()
            getCurrentSession().then(userSession => {
                if(userSession){
                    const jwt = userSession.getIdToken().decodePayload();
                    if (jwt['cognito:groups'] && jwt['cognito:groups'].indexOf("AnonymousUsers") !== -1) {
                        setIsAnonymousUser()
                    }else{
                        clearIsAnonymousUser()
                        handleLoginWithSession(userSession)
                    }
                }
            })
            console.log('user signed in');
            break;
        case 'signIn_failure':
            console.log('user sign in failed');
            console.log(getWhiteLabelClubName());
            if(checkIsWhiteLabelledByQPR() && !getItem("retryKoreQPR")){
                setItem("retryKoreQPR", "true")
                console.log("retry qprKore sign in")
                socialSignIn(SocialProvider.KOREQPR)
            }
            if(checkIsWhiteLabelledByPUFC()){ //&& !getItem("retryClubcastPUFC")){
                setItem("retryClubcastPUFC", "true")
                console.log("retry ClubcastPUFC sign in")
                socialSignIn(SocialProvider.ClubcastPUFC)
            }
            break;
        case 'signUp':
            console.log('user signed up');
            break;
        case 'signUp_failure':
            console.log('user sign up failed');
            break;
        case 'confirmSignUp':
            console.log('user confirmation successful');
            break;
        case 'completeNewPassword_failure':
            console.log('user did not complete new password flow');
            break;
        case 'autoSignIn':
            console.log('auto sign in successful');
            break;
        case 'autoSignIn_failure':
            console.log('auto sign in failed');
            break;
        case 'forgotPassword':
            console.log('password recovery initiated');
            break;
        case 'forgotPassword_failure':
            console.log('password recovery failed');
            break;
        case 'forgotPasswordSubmit':
            console.log('password confirmation successful');
            break;
        case 'forgotPasswordSubmit_failure':
            console.log('password confirmation failed');
            break;
        case 'verify':
            console.log('TOTP token verification successful');
            break;
        case 'tokenRefresh':
            console.log('token refresh succeeded');
            break;
        case 'tokenRefresh_failure':
            console.log('token refresh failed');
            break;
        case 'cognitoHostedUI':
            console.log('Cognito Hosted UI sign in successful');
            break;
        case 'cognitoHostedUI_failure':
            console.log('Cognito Hosted UI sign in failed');
            break;
        case 'customOAuthState':
            console.log('custom state returned from CognitoHosted UI');
            break;
        case 'customState_failure':
            console.log('custom state failure');
            break;
        case 'parsingCallbackUrl':
            console.log('Cognito Hosted UI OAuth url parsing initiated');
            break;
        case 'userDeleted':
            console.log('user deletion successful');
            break;
        case 'updateUserAttributes':
            console.log('user attributes update successful');
            break;
        case 'updateUserAttributes_failure':
            console.log('user attributes update failed');
            break;
        case 'signOut':
            console.log('user signed out');
            break;
        default:
            console.log('unknown event type');
            break;
    }
};

Hub.listen('auth', listener);