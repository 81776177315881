import React, {useCallback, useEffect} from "react"
import {useAppSelector} from "../../../redux/hooks";
import {ICreateOrderDetails} from "../../order/orderSlice";
import {RootState} from "../../../redux/store";
import {Button, CircularProgress, styled, Typography, useTheme} from "@mui/material";
import {CheckCircleOutline} from "@mui/icons-material";
import {ViaUserProfile} from "../../user/userSlice";
import {useNavigate, useSearchParams} from "react-router-dom";
import {consentServiceApi} from "../../../services/consent-service";
import {routes} from "../../../constants/routes";
import {CenteredBodyBox, FullScreenContent} from "../../../shared/FullScreenContent";
import {ColumnBox} from "../../../shared/styles";
import {Analytics} from "../../../lib/analytics";
import { Box } from '@mui/material';

const StyledButton = styled(Button)(({theme}) => ({
    marginBottom: "10px",
    minWidth: "200px",
    borderRadius: "24px",
    textTransform: "none",
    fontSize: "16px",
    fontWeight: "600",
    backgroundColor: theme.custom.isWhiteLabelled ? theme.custom.wl.bottomCTA.buttonBackgroundColor : undefined,
    color: theme.custom.isWhiteLabelled ? theme.custom.wl.bottomCTA.bgColor : undefined,
}));
const SpacedTypography = styled(Typography)(({theme}) => ({
    margin: "20px 0",
    [theme.breakpoints.down("sm")]: {
        margin: 0
    },
}));
export const PurchasedOrderCreated = (): JSX.Element => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const recipientEmail =searchParams.get("recipientEmail");
    const orderDetails = useAppSelector<ICreateOrderDetails | null>((state: RootState) => state.orderSlice.orderDetails);
    const profile = useAppSelector<ViaUserProfile|undefined>((state: RootState) => state.user.profile);
    const {isLoading, data: parentalEmail} = consentServiceApi.useFetchConsentStatusQuery("", { skip: !profile || !profile.user.is_minor });
    console.log(parentalEmail)

    useEffect(() => {
        if(!!recipientEmail && !!orderDetails && typeof fbq === 'function' ){
            console.log('fbq Purchase')
            fbq('track', 'Purchase', {}, {
                em: recipientEmail
            });
        }
    }, [recipientEmail,orderDetails]);


    const goToVideos = useCallback(() => {
        Analytics.purchaseConfirmationMyVideosButtonClick();
        navigate(routes.coachFeedback);
    }, [navigate]);
    const submitAnotherVideo = useCallback(() => {
        Analytics.purchaseConfirmationAnotherVideoButtonClick();
        navigate(routes.purchase);
    }, [navigate]);

    useEffect(() => {
        Analytics.purchaseConfirmationView(); 
    }, []);

    if (!orderDetails) {
        return <></>;
    }

    return (
        <>
            {isLoading && <CircularProgress sx={{m: "10px auto"}} />}
            {!isLoading && (
                <FullScreenContent
                    actionButtons={<ColumnBox>
                        <StyledButton variant="contained" onClick={goToVideos}>Go to My Videos</StyledButton>
                        {!theme.custom.isWhiteLabelled && <StyledButton variant="outlined" color="secondary" onClick={submitAnotherVideo}>Submit Another Video</StyledButton>}
                    </ColumnBox>}
                >
                    <CenteredBodyBox sx={{justifyContent: "flex-start"}}>
                        <CheckCircleOutline sx={{width: "40px", height: "40px"}} />
                        <SpacedTypography variant="body2" color="secondary">Thanks for submitting your video</SpacedTypography>
                        <Box sx={{
                            border: `1px solid ${theme.custom.isWhiteLabelled ? theme.palette.secondary.main : 'white'}`,
                            padding: '20px',
                            margin: '10px 0',
                            backgroundColor: 'transparent',
                            textAlign: 'center'
                        }}>
                            <Typography variant="body1" sx={{fontWeight: "750", textTransform: "uppercase", paddingBottom: '25px'}} color="secondary">Order Details</Typography>
                            <Typography variant="body1" sx={{fontWeight: "500", color: theme.custom.isWhiteLabelled ? theme.palette.secondary.main : theme.palette.grey[500], paddingBottom: '15px'}}>Order Number: {orderDetails.id}</Typography>
                            {profile && <Typography variant="body2" align="center" sx={{fontWeight: "500", color: theme.custom.isWhiteLabelled ? theme.palette.secondary.main : theme.palette.grey[500], paddingBottom: '8px'}}>
                            Your order confirmation has been sent to <br />{profile.user.email}
                            </Typography>}
                            <Typography variant="body1" sx={{fontWeight: "500", color: theme.custom.isWhiteLabelled ? theme.palette.secondary.main : theme.palette.grey[500]}}>Your feedback will be ready within 10 days.</Typography>
                        </Box>
                        <Typography variant="body1" sx={{fontWeight: "300", textAlign: "center", color: theme.custom.isWhiteLabelled ? theme.palette.secondary.main : theme.palette.grey[500]}}>We’ll notify you at the above email address when your feedback is ready.</Typography>
                    </CenteredBodyBox>
                </FullScreenContent>
            )}
        </>
    );
}
