import React, {useEffect, useState} from 'react'
import {Box, Button, styled, Typography, useTheme} from "@mui/material";
import {routes} from "../constants/routes";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {getUserSubscriptionsThunk} from "../features/subscriptions/subscriptionsSlice";
import {RootState} from "../redux/store";
import {checkIsAnonymousUser} from "../util/storage";
import {AskParent} from "../views/feedback/components/AskParent";
import {Analytics} from "../lib/analytics";


const StyledFilledHeader = styled(Box)(({theme}) => ({
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF",
    padding: "24px 32px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
}));


const StyledTopHeader = styled(Typography)( ()=> ({
    fontSize: "18px",
    fontWeight: "600",
}));

const StyledTopText = styled(Typography)( ()=> ({
    fontSize: "14px",
    fontWeight: "400",
}));

const StyledSubWallBox = styled(Box)(({theme}) => ({
    color: theme.custom.darkerPrimaryColor,
    padding: "24px 24px 0 16px",
}));

const StyledChoice = styled(Box)(({theme}) => ({
    borderRadius: "8px",
    padding: "16px 24px",
    borderWidth: "3px",
    borderStyle: "solid",
    borderColor: "#FFFFFF",
    "&:hover": {
        cursor: "pointer"
    },
    "&.selected": {
        borderColor: theme.custom.subscriptionChoiceBorderColor,
        backgroundColor: "#FFFFFF",
    },
    backgroundColor: "#EEEEEE"
}))

const ChoiceText1 = styled(Typography)(()=>({
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "12px",
    textAlign: 'center',
}))

const ChoiceText2 = styled(Typography)(()=>({
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "12px",
    textAlign: 'center',
}))

const PriceBox = styled(Box)(()=>({
    padding: "8px 0",
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: "4px",
    "& .price-label":{
        fontSize: "20px",
        fontWeight: "700",
        lineHeight: "32px",
    },
    "& .slashed":{
        fontSize: "10px",
        fontWeight: "400",
        lineHeight: "22px",
        color: "#AAAAAA",
        // textDecoration: "line-through",
    },
    "& .slashed:before": {
        position: "absolute",
        content: "''",
        left: 0,
        top: "50%",
        right: 0,
        borderTop: "0.7px solid",
        transform: "rotate(-8deg)",
        borderColor: "red"
    }
}))

const StyledNote = styled(Typography)(()=>({
    margin: "16px 0 40px 0",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: 'center',
}))

const StyledParentButton = styled(Button)(()=>({
    "&.Mui-disabled": {
        backgroundColor: "#999999 !important",
        color: "#FFFFFF !important"
    },
    justifyContent: "center",
    padding: "10px;",
    color: "#FFFFFF",
    textTransform: "uppercase"
}))

// const LegalNote = styled(Typography)(()=>({
//     marginTop: "24px",
//     marginBottom: "8px",
//     fontSize: "14px",
//     fontWeight: 400,
//     lineHeight: "20px",
//     // textAlign: 'center',
// }))


export const SubscriptionWall = (): React.ReactElement => {
    const theme = useTheme()
    const dispatch = useAppDispatch()
    const [selectedPlan, setSelectedPlan] = useState<string>("Monthly")
    const navigate = useNavigate()

    const trialUsed = useAppSelector<boolean>((state: RootState) => state.subscriptions.trialUsed)

    const nextSlug = trialUsed ? '1' : 'start-trial'

    // const mySubscriptions = useAppSelector<UserSubscription[]>((state: RootState) => state.subscriptions.mySubscriptions);

    useEffect(() => {
        dispatch(getUserSubscriptionsThunk())
    },[dispatch]);

    // const monthlyPlanSKU = theme.custom.monthlyProSubscriptionSKU
    // const yearlyPlanSKU = theme.custom.yearlyProSubscriptionSKU
    useEffect(() => {
        Analytics.subscriptionWallView();
    },[]);

    return (
        <Box>
            <StyledFilledHeader>
                <img style={{maxHeight: "32px", maxWidth: !!theme.custom.brandAcademyLogoHorizontal ? undefined : "32px"}}
                     src={!!theme.custom.brandAcademyLogoHorizontal ? theme.custom.brandAcademyLogoHorizontal : theme.custom.brandTitleLogo}
                     alt={(theme.custom.isWhiteLabelled ? theme.custom.academyCommonName || "Via Academy" : "Via Academy") + " Logo"}/>
                <StyledTopHeader>Pro Membership</StyledTopHeader>
                <StyledTopText>Access exclusive {theme.custom.academyCommonName} Training videos.</StyledTopText>
                <StyledTopText>New videos are uploaded every week.</StyledTopText>
            </StyledFilledHeader>
            <StyledSubWallBox>
                <Typography sx={{
                    fontWeight: "500",
                    fontSize: "18px",
                    lineHeight: "26px",
                    textAlign: "center",
                    marginBottom: "16px",
                }}>Choose plan</Typography>
                <Box
                    sx={{
                        display: "flex",
                        gap: "16px",
                        flexDirection: "row",
                        justifyContent: "center",
                    }}
                >
                    <StyledChoice onClick={() => setSelectedPlan("Monthly")} className={selectedPlan === "Monthly" ? "selected" : ""}
                    >
                        <ChoiceText1>1 Month</ChoiceText1>
                        <PriceBox>
                            <Typography className="price-label">£5.99</Typography>
                            {/*<Typography sx={{position: 'relative'}} className="slashed">£59.88</Typography>*/}
                        </PriceBox>
                        <ChoiceText2>Renews monthly</ChoiceText2>
                    </StyledChoice>
                    <StyledChoice onClick={() => setSelectedPlan("Yearly")} className={selectedPlan === "Yearly" ? "selected" : ""}
                    >
                        <ChoiceText1>1 Year</ChoiceText1>
                        <PriceBox>
                            <Typography className="price-label">£59.99</Typography>
                            <Typography sx={{position: 'relative'}} className="slashed">£71.88</Typography>
                        </PriceBox>
                        <ChoiceText2>Renews yearly</ChoiceText2>
                    </StyledChoice>


                </Box>

                { selectedPlan === 'Monthly' && !trialUsed &&
                    <StyledNote>Trial 1 week for FREE, then £5.99 a month. Cancel at any time.</StyledNote>
                }
                { selectedPlan === 'Yearly' && !trialUsed &&
                    <StyledNote>Trial 1 week for FREE, then £59.99 a year. Cancel at any time.</StyledNote>
                }
                {trialUsed &&
                    <StyledNote></StyledNote>
                }

                {checkIsAnonymousUser() ?
                    <Box sx={{textAlign: "center"}}>
                        <StyledParentButton
                            disabled={true}
                            variant="contained"
                        >Ask your parent/guardian</StyledParentButton>
                        <AskParent topic="training"/>
                    </Box>
                    :
                    <Button
                        variant="contained"
                        sx={{
                            justifyContent: "center",
                            padding: "10px;",
                            color: "#FFFFFF",
                            backgroundColor: theme.custom.newButtonColor
                        }}
                        fullWidth
                        disableElevation={true}
                        onClick={
                            ()=> {
                                Analytics.subscriptionWallStartFreeTrialButtonClick(selectedPlan.toLowerCase());
                                navigate(routes.subscribe.replace(":step",nextSlug).replace(":plan", selectedPlan.toLowerCase()))
                            }
                        }
                    >{ trialUsed ? "SUBSCRIBE TO PRO MEMBERSHIP" : "START FREE TRIAL" }</Button>
                }
            </StyledSubWallBox>
        </Box>
    )

}